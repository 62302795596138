import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { Heading, Grid, GridItem, Box, Text, Image } from "@chakra-ui/react";
import { userHasAccess } from "@impact-theory/app-auth";

import { ComicItem } from "../components/ComicItem";

const comics = [
  {
    title: "Comic-1",
    description: "Description1 for Comic 1",
    image: "https://placehold.co/600x400",
  },
  {
    title: "Comic-2",
    description: "Description1 for Comic 2",
    image: "https://placehold.co/600x400",
  },
  {
    title: "Comic-4",
    description: "Description1 for Comic 4",
    image: "https://placehold.co/600x400",
  },
  {
    title: "Comic-3",
    description: "Description1 for Comic 3",
    image: "https://placehold.co/600x400",
  },
  {
    title: "Comic-6",
    description: "Description1 for Comic 6",
    image: "https://placehold.co/600x400",
  },
  {
    title: "Comic-8",
    description: "",
    image: "https://placehold.co/600x400",
  },
  {
    title: "Comic-4",
    description: "Description1 for Comic 4",
    image: "https://placehold.co/600x400",
  },
  {
    title: "Comic-3",
    description: "Description1 for Comic 3",
    image: "https://placehold.co/600x400",
  },
  {
    title: "Comic-6",
    description: "Description1 for Comic 6",
    image: "https://placehold.co/600x400",
  },
];

const ComicsList = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    async function run() {
      const result = await userHasAccess();
      setLoggedIn(result);
    }
    run();
  }, []);

  if (!loggedIn)
    return (
      <Layout>
        <Heading marginBottom="2rem">No Permission</Heading>
      </Layout>
    );

  return (
    <Layout>
      <Heading marginBottom="2rem">Comic List</Heading>
      <Grid templateColumns="repeat(6, 1fr)" gap={3}>
        {comics.map((item, index) => (
          <GridItem key={index}>
            <ComicItem title={item.title} image={item.image} />
          </GridItem>
        ))}
      </Grid>
    </Layout>
  );
};

export default ComicsList;
