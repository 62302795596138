import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  InputLeftElement,
  InputGroup,
} from "@chakra-ui/react";
import { useState, useRef } from "react";

const EpisodeForm = () => {
  const toast = useToast();
  const [formData, setFormData] = useState({
    name: "",
    number: undefined,
    price: undefined,
    image: null,
  });
  const fileInputRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(formData);
      //   const res = await api.createTournament(formData);
      //     if (res) {
      //         toast({
      //             title: 'Tournament created.',

      //             status: 'success',
      //             duration: 9000,
      //             isClosable: true,
      //           })
      //     }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageSelect = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl>
        <FormLabel>Name</FormLabel>
        <Input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Number</FormLabel>
        <Input
          type="number"
          name="number"
          value={formData.number}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Price</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            color="gray.300"
            fontSize="1.2em"
            children="$"
          />
          <Input
            type="number"
            name="price"
            value={formData.price}
            onChange={handleChange}
          />
        </InputGroup>
      </FormControl>
      <FormControl mt={2}>
        <Button colorScheme="blue" onClick={() => fileInputRef.current.click()}>
          Upload Episide image
        </Button>
        {formData.image && (
          <Box display="inline" ml={2}>
            {formData.image.name}
          </Box>
        )}
        <Input
          onChange={handleImageSelect}
          ref={fileInputRef}
          type="file"
          name="image"
          hidden
          required
        />
      </FormControl>
      <Button
        mt={4}
        colorScheme="blue"
        type="submit"
        isDisabled={
          !formData.name ||
          !formData.number ||
          !formData.price ||
          !formData.image
        }
      >
        Save
      </Button>
    </form>
  );
};

export default EpisodeForm;
