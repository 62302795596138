import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Textarea,
} from "@chakra-ui/react";
import { useState, useRef } from "react";

const ComicForm = () => {
  const toast = useToast();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: null,
  });
  const fileInputRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(formData);
      //   const res = await api.createTournament(formData);
      //     if (res) {
      //         toast({
      //             title: 'Tournament created.',

      //             status: 'success',
      //             duration: 9000,
      //             isClosable: true,
      //           })
      //     }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageSelect = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl>
        <FormLabel>Title</FormLabel>
        <Input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
          required
        />
      </FormControl>
      <FormControl>
        <FormLabel>Description</FormLabel>
        <Textarea
          type="textfield"
          name="description"
          value={formData.description}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl mt={2}>
        <Button colorScheme="blue" onClick={() => fileInputRef.current.click()}>
          Upload Cover Image
        </Button>
        {formData.image && (
          <Box display="inline" ml={2}>
            {formData.image.name}
          </Box>
        )}
        <Input
          onChange={handleImageSelect}
          ref={fileInputRef}
          type="file"
          name="image"
          hidden
          required
        />
      </FormControl>
      <Button
        mt={4}
        colorScheme="blue"
        type="submit"
        isDisabled={!formData.title || !formData.image}
      >
        Save
      </Button>
    </form>
  );
};

export default ComicForm;
