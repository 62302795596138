import lifecycles from "./impact-theory-app-all-systems-go";

export const allSystemGoSubMenu = [
  {
    label: "View Comics",
    subLabel: "View Comics",
    href: "/all-systems-go/comics",
  },
  {
    label: "Create Comics",
    subLabel: "Create Comics",
    href: "/all-systems-go/create-comics",
  },
  {
    label: "Create Episode",
    subLabel: "Create Episode",
    href: "/all-systems-go/create-episode",
  },
];

export const { bootstrap, mount, unmount } = lifecycles;
