import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CreateComic from "./pages/CreateComic";
import CreateEpisode from "./pages/CreateEpisode";
import ComicList from "./pages/ComicList";

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/all-systems-go/create-comics"
            element={<CreateComic />}
          />
          <Route path="/all-systems-go/comics" element={<ComicList />} />
          <Route
            path="/all-systems-go/create-episode"
            element={<CreateEpisode />}
          />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
