import { Box, Image, Text } from "@chakra-ui/react";

export const ComicItem = ({ title, description, image }) => {
  return (
    <Box
      border="1px solid black"
      borderRadius="5px 10px"
      cursor="pointer"
      padding="5px"
    >
      <Image width="100%" height="200px" objectFit="cover" src={image} />
      <Text align="center" pt={2}>
        {title}
      </Text>
    </Box>
  );
};
